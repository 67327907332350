import styled from "styled-components";

export const UserListWrapper = styled.div`
  .ant-pagination {
    display: flex;
    justify-content: center;
  }
  .ant-card {
    margin-bottom: 5px;
  }
`;

export const UserImage = styled.img`
  height: 50px;
  width: 50px;
  margin-right: 20px;
  border-radius: 100%;
  object-fit: cover;
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    height: 25px;
    width: 25px;
    margin-right: 10px;
  }
`;

export const UserItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin-bottom: 10px;
  border-bottom: 1px solid #989898;
  padding: 10px;
`;

export const CardContentWrapper = styled.div`
  min-height: 30em;
  max-height: 40vh;
  overflow: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const SubTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    flex-direction: column;
    align-items: center;
  }
`;

export const SearchWrapper = styled.div`
  input {
    padding: 5px;
  }
`;

export const SelectAllWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  span {
    margin-right: 5px;
  }
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    justify-content: center;
    margin-top: 5px;
  }
`;

export const SelectedCount = styled.span`
  margin-left: 10px;
  font-size: 16px;
  color: grey;
`;

export const TitleWrapper = styled.h2`
  display: flex;
  align-items: center;
`;

export const MessageWrapper = styled.div`
  span.ant-typography {
    text-align: left;
  }
  textarea.ant-input {
    font-size: 16px;
  }
`;

export const EmojiButton = styled.div`
  display: inline;
  margin-left: 10px;
  width: 10px !important;
  position: relative;
  @media only screen and (max-width: 767px) {
    margin-left: 0px;
    margin-bottom: 10px;
    width: auto !important;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  width: 90% !important;
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    flex-direction: column-reverse;
    align-items: center;
  }

  justify-content: space-between;
  textarea.ant-input {
    min-height: 100px;
  }
`;

export const EmojiWrapper = styled.div`
  position: absolute;
  bottom: 170px;
  right: 0px;
  max-width: fit-content;
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    left: 50%;
    transform: translate(-50%, 0%);
  }

  visibility: ${(props) => (props.active ? "visible" : "hidden")};
  .emoji-picker .emoji {
    padding: 15px !important;
  }
`;

export const TextInput = styled.div``;
