import { Entity } from "@thewebops/admin-panel";

export default new Entity({
  name: "exercise",
  pluralName: "exercises",
  pathName: "exercises",
  endpoint: "/coaches/exercises",
  serverName: "exercise",
  serverPluralName: "exercises",
  displayName: "Exercises",
  allowCreate: false,
  allowEdit: false,
  allowDelete: false,
  showRowActions: false,
  showSearch: true,
  titleField: "name",
  filters: [
    {
      label: "Program",
      name: "program_id",
      type: "select",
      // selectConfig is required if [select] type is used otherwise it's ommitted
      selectConfig: {
        optionsType: "dynamic",
        defaultValue: "first",

        // optionsEntity is required if optionsType is dynamic
        optionsEntity: {
          name: "program",
          labelField: "name",
          valueField: "id",
        },
      },
    },
    {
      label: "Start Date",
      name: "start_date",
      type: "date",
      // selectConfig is required if [select] type is used otherwise it's ommitted
      selectConfig: {
        optionsType: "static",
      },
    },
    {
      label: "End Date",
      name: "end_date",
      type: "date",
      // selectConfig is required if [select] type is used otherwise it's ommitted
      selectConfig: {
        optionsType: "static",
      },
    },
  ],
  fields: [
    {
      identifier: "id",
      label: "ID",
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "program_id",
      label: "Program",
      accessor: (dataObject) => (dataObject.program ? dataObject.program.name : null),
      formAccessor: (dataObject) => (dataObject.program ? dataObject.program.id : null),
      inList: true,
      type: "reference",
      required: true,
      optionsEntity: {
        name: "program",
        labelField: "name",
        valueField: "id",
      },
    },
    {
      identifier: "name",
      label: "Name",
      accessor: "name",
      inList: true,
      usedToShow: true,
      type: "string",
      required: true,
    },
    {
      identifier: "due_date",
      label: "Due Date",
      accessor: "due_date",
      inList: true,
      type: "date",
      required: true,
    },
    {
      identifier: "body",
      label: "Content",
      accessor: "body",
      inList: false,
      type: "editor",
      required: true,
    },
    {
      identifier: "is_interactive",
      label: "Interactive / Noninteractive",
      accessor: (dataObject) => (dataObject.non_interactive ? 1 : 0),
      type: "select",
      required: false,
      defaultValue: 0,
      options: [
        { label: "Interactive", value: 0 },
        { label: "Not Interactive?", value: 1 },
      ],
    },
    {
      identifier: "question_attributes",
      accessor: "question",
      label: "Question & Answer",
      type: "dynamic",
      preventMultiple: true,
      fields: [
        {
          identifier: "id",
          label: "ID",
          accessor: "id",
          hideInCreate: true,
          hideInEdit: true,
          hideInShow: true,
        },
        {
          identifier: "question_type",
          label: "Question Type",
          accessor: "question_type",
          type: "select",
          required: true,
          options: [
            { value: "yes_or_no", label: "yes or no question" },
            { value: "text", label: "text question" },
          ],
        },
        {
          identifier: "question_body",
          label: "Question Body",
          accessor: "question_body",
          type: "text",
          required: true,
        },
      ],
    },
  ],
});
