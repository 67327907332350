import React, { useState } from "react";
import { Input, Typography, Button } from "antd";
import { MessageWrapper, EmojiButton, InputWrapper, TextInput, EmojiWrapper } from "./styled";
import EmojiPicker from "emoji-picker-react";
import OutsideClickHandler from "react-outside-click-handler";

const Message = ({ field, onChange, state, value }) => {
  const [messageValue, setMessageValue] = useState(value);
  const [isEmojiOpen, setIsEmojiOpen] = useState(false);

  const changeMessage = (body) => {
    setMessageValue(body);
    onChange("message", { body });
    onChange(field, body);
  };

  const onTextChange = (event) => {
    const { value: body } = event.target;
    changeMessage(body);
  };

  const toggleEmoji = () => setIsEmojiOpen((emoji) => !emoji);

  const closeEmoji = () => toggleEmoji(false);

  const onEmoji = (e, emojiObject) => {
    const value = `${String.fromCodePoint(parseInt(emojiObject.unified, 16))}`;
    const newMessageValue = `${messageValue} ${value}`;
    changeMessage(newMessageValue);
  };

  return (
    <MessageWrapper>
      <Typography.Text strong>Message</Typography.Text>
      <Typography.Text>
        {" "}
        {"Type {{userName}} anywhere in the message to send a personalized message according to each user"}{" "}
      </Typography.Text>
      <InputWrapper>
        <TextInput>
          <Input.TextArea value={messageValue} onChange={onTextChange} />
        </TextInput>
        <EmojiButton>
          <Button onClick={toggleEmoji}>
            <span role="img" aria-label="Emoji">
              😃
            </span>
          </Button>
        </EmojiButton>
      </InputWrapper>
      {state.formErrors[field.identifier] && (
        <Typography.Text type="danger" strong>
          This field is required
        </Typography.Text>
      )}

      {isEmojiOpen && (
        <OutsideClickHandler display="contents" onOutsideClick={closeEmoji}>
          <EmojiWrapper active={isEmojiOpen}>
            <EmojiPicker disableAutoFocus onEmojiClick={onEmoji} />
          </EmojiWrapper>
        </OutsideClickHandler>
      )}
    </MessageWrapper>
  );
};

export default Message;
