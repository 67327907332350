import { Entity } from "@thewebops/admin-panel";

export default (authConfig) =>
  new Entity({
    title: `Complete your ${authConfig.displayName} profile`,
    subTitle: `Enter the credentials associated with your ${authConfig.displayName} account.`,
    path: "/completeProfile/:token",
    submitButtonText: "Complete my profile",
    action: "create",
    useFormData: true,
    fields: [
      {
        label: "Name",
        required: true,
        type: "string",
        identifier: "name",
      },
      {
        label: "New Password",
        required: true,
        type: "password",
        identifier: "password",
      },
      {
        label: "Confirm new Password",
        required: true,
        type: "password",
        identifier: "password_confirmation",
      },
      {
        label: "Image",
        required: true,
        type: "image",
        identifier: "image",
      },
    ],
    requestConfig: {
      url: `/${authConfig.serverPluralName}/confirmations`,
      method: "put",
      successMessage: "Profile completed",
      errorMessage: "Couldn't complete profile",
      payloadWrapper: {
        name: "coach",
        fields: ["password", "password_confirmation", "name", "image"],
      },
      successRedirectPath: "/login",
      useFormData: true,
    },
    queryParamName: "confirmation_token",
  });
