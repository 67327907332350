import answer from "./Answer";
import exercise from "./Exercise";
import habit from "./Habit";
import checkup from "./Checkup";
import lesson from "./Lesson";
import savedReply from "./SavedReply";
import savedReplyCategory from "./SavedReplyCategory";
import initialAssesment from "./InitialAssesment";
import program from "./Program";
import user from "./User";

const userDefinedEntities = {
  user,
  answer,
  exercise,
  habit,
  checkup,
  lesson,
  savedReply,
  savedReplyCategory,
  initialAssesment,
  program,
};

const groups = [
  {
    name: "ungrouped",
    entities: [
      user,
      answer,
      exercise,
      habit,
      checkup,
      lesson,
      savedReply,
      savedReplyCategory,
      initialAssesment,
      program,
    ],
  },
];
export { userDefinedEntities, groups };
