import { Entity } from "@thewebops/admin-panel";

export default new Entity({
  name: "savedReply",
  pluralName: "savedReplies",
  pathName: "saved_replies",
  endpoint: "/coaches/saved_replies",
  serverName: "saved_reply",
  serverPluralName: "saved_replies",
  displayName: "Saved Replies",
  allowCreate: false,
  allowEdit: false,
  allowDelete: true,
  showRowActions: true,
  showSearch: false,
  filters: [
    {
      label: "Saved Reply Category",
      name: "category_id",
      type: "select",
      selectConfig: {
        optionsType: "dynamic",
        optionsEntity: {
          name: "savedReplyCategory",
          labelField: "name",
          valueField: "id",
        },
      },
    },
  ],
  fields: [
    {
      identifier: "id",
      label: "ID",
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "saved_reply_category_id",
      label: "Saved Reply Category",
      formAccessor: (dataObject) => (dataObject.saved_reply_category ? dataObject.saved_reply_category.id : null),
      accessor: (dataObject) => (dataObject.saved_reply_category ? dataObject.saved_reply_category.name : null),
      inList: true,
      type: "reference",
      optionsEntity: {
        name: "savedReplyCategory",
        labelField: "name",
        valueField: "id",
      },
    },
    {
      identifier: "message_id",
      label: "Message",
      formAccessor: (dataObject) => (dataObject.message ? dataObject.message.id : null),
      accessor: (dataObject) => (dataObject.message ? dataObject.message.body : null),
      inList: true,
      type: "string",
    },
    {
      identifier: "attachment_url",
      label: "Attachment",
      formAccessor: (dataObject) => (dataObject.message ? dataObject.message.id : null),
      accessor: (dataObject) => (dataObject.message ? dataObject.message.attachment_url : null),
      inList: true,
      type: "string",
    },
  ],
});
