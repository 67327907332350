import { Entity } from "@thewebops/admin-panel";

export default new Entity({
  name: "program",
  pluralName: "programs",
  pathName: "programs",
  endpoint: "/coaches/programs",
  serverName: "program",
  serverPluralName: "programs",
  displayName: "Programs",
  showSearch: false,
  customErrorKeys: ["program", "master_coach"],
  titleField: "name",
  fields: [
    {
      identifier: "name",
      label: "Name",
      accessor: "name",
      inList: true,
      type: "string",
      required: true,
    },
    {
      identifier: "start_date",
      label: "Start Date",
      accessor: "start_date",
      inList: true,
      type: "date",
      required: true,
    },
    {
      identifier: "end_date",
      label: "End Date",
      accessor: "end_date",
      inList: true,
      type: "date",
      required: true,
    },
    {
      identifier: "price",
      label: "Price",
      accessor: "price",
      inList: true,
      type: "number",
      required: true,
    },
    {
      identifier: "master_coach_id",
      label: "Master Coach",
      accessor: (dataObject) => (dataObject.master_coach ? dataObject.master_coach.name : null),
      formAccessor: (dataObject) => (dataObject.master_coach ? dataObject.master_coach.id : null),
      type: "reference",
      optionsEntity: {
        name: "coach",
        labelField: "name",
        valueField: "id",
      },
    },
    {
      identifier: "source_program_id",
      label: "Source Program",
      hideInEdit: true,
      inList: false,
      accessor: (dataObject) => (dataObject.source_program ? dataObject.source_program.name : null),
      formAccessor: (dataObject) => (dataObject.source_program ? dataObject.source_program.id : null),
      type: "reference",
      optionsEntity: {
        name: "program",
        labelField: "name",
        valueField: "id",
      },
    },
  ],
});
