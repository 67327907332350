import login from "./login";
import forgetPassword from "./forgetPassword";
import resetPassword from "./resetPassword";
import completeProfile from "./completeProfile";
import resendConfirmation from "./resendConfirmation";
import sendBroadcast from "./sendBroadcast";

const authConfig = {
  name: "coach",
  pluralName: "coaches",
  pathName: "coaches",
  serverName: "coach",
  serverPluralName: "coaches",
  displayName: "Coach",
};

const authUserDefinedEntities = [
  login,
  forgetPassword,
  resetPassword,
  completeProfile,
  resendConfirmation,
  sendBroadcast,
];

export { authConfig, authUserDefinedEntities };
