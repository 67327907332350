import { Entity } from "@thewebops/admin-panel";

export default (authConfig) =>
  new Entity({
    title: "Login to your account",
    subTitle: `Enter the credentials associated with your ${authConfig.displayName} account.`,
    path: "/login",
    actionLabel: "Login",
    logoTitle: "Nutivate",
    action: "create",
    submitButtonText: "Login",
    fields: [
      {
        label: "Email",
        required: true,
        type: "email",
        identifier: "email",
      },
      {
        label: "Password",
        required: true,
        type: "password",
        identifier: "password",
      },
    ],
    requestConfig: {
      url: `/${authConfig.serverPluralName}/sign_in`,
      method: "post",
      saveToLocalStorage: true,
    },
  });
