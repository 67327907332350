import notificationSound from "../assets/notification.wav";

const audio = new Audio(`${notificationSound}`);

const playNotificationSound = () => {
  try {
    audio.play();
  } catch (error) {
    console.warn("The user must first interact with the browser");
  }
};

export default playNotificationSound;
