import { Entity } from "@thewebops/admin-panel";

export default (authConfig) =>
  new Entity({
    title: "Resend confirmation email",
    subTitle: "Enter the email associated with your account to send a confirmation email",
    path: "/resendConfirmationEmail",
    submitButtonText: "Send",
    action: "create",
    fields: [
      {
        label: "Email",
        required: true,
        type: "email",
        identifier: "email",
      },
    ],
    requestConfig: {
      url: `/${authConfig.serverPluralName}/confirmations`,
      method: "POST",
      successMessage: "Email Confirmation Resent",
      errorMessage: "Couldn't send email",
    },
  });
