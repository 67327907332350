import React, { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getEntity, entitiesActions } from "@thewebops/admin-panel";
import { Card, Skeleton, Pagination, Checkbox, Input, Typography } from "antd";
import {
  UserListWrapper,
  CardContentWrapper,
  TitleWrapper,
  SubTitleWrapper,
  SearchWrapper,
  SelectAllWrapper,
  SelectedCount,
} from "./styled";

import user from "../../entities/User";
import UserItem from "./UserItem";

const optionLabel = {
  active: "Active",
  partially_active: "Partially Active",
  not_active: "Not Active",
  all: "All",
};

const UserBroadcast = ({ field, onChange, state }) => {
  const dispatch = useDispatch();
  const { data, loading } = useSelector((state) => getEntity(state, user.pluralName));
  const [currentPage, setCurrentPage] = useState(1);
  const [isAllSelected, setIsAllSelected] = useState(true);
  const [dataSet, setDataSet] = useState({ all: {}, selected: {} });
  const [currentSearchData, setCurrentSearchData] = useState(null);
  const topList = useRef();

  useEffect(() => {
    if (data.length > 0) {
      const newData = data.reduce(
        (accum, currentRecord) => {
          accum.all[currentRecord.id] = currentRecord;
          accum.selected[currentRecord.id] = true;
          return accum;
        },
        { all: {}, selected: {} },
      );
      setDataSet(newData);
    }
  }, [data]);

  useEffect(() => {
    const userIds = Object.keys(dataSet.selected);
    onChange("targeted_participants", { users: Object.keys(dataSet.selected) });
    const fieldValue = userIds.length > 0 ? true : "";
    onChange(field, fieldValue);
  }, [dataSet.selected, onChange]);

  const resetState = useCallback(() => {
    setIsAllSelected(true);
    setDataSet({ all: {}, selected: {} });
    setCurrentSearchData(null);
    setCurrentPage(1);
  }, []);

  useEffect(() => {
    dispatch(
      entitiesActions[user.name].list.request({
        entity: user,
        show_all: true,
        activity_status: state.segments,
        active_programs: true,
      }),
    );
    return () => {
      dispatch(entitiesActions[user.name].list.reset());
      resetState();
    };
  }, [state.segments, dispatch, resetState]);

  const onChangePage = (page) => {
    setCurrentPage(page);
    topList.current.scrollBy({ top: -1 * topList.current.scrollHeight, behaviour: "smooth" });
  };

  const onSelectAll = (event) => {
    const { checked } = event.target;
    setIsAllSelected((isAllSelected) => !isAllSelected);
    if (checked)
      setDataSet((dataSet) => {
        const newDataSet = { ...dataSet };
        const newAllSelected = Object.keys(dataSet.all).reduce((accum, key) => ({ ...accum, [key]: true }), {});
        newDataSet.selected = newAllSelected;
        return newDataSet;
      });
    else setDataSet((dataSet) => ({ ...dataSet, selected: {} }));
  };

  const onCheckUser = useCallback((checked, id) => {
    setDataSet((dataSet) => {
      const newDataSet = { ...dataSet };
      if (checked) newDataSet.selected[id] = true;
      else delete newDataSet.selected[id];
      newDataSet.selected = { ...newDataSet.selected };
      return newDataSet;
    });
    if (!checked) setIsAllSelected(false);
  }, []);

  const onSearch = (event) => {
    setCurrentPage(1);
    if (!event.target.value) {
      setCurrentSearchData(null);
      return;
    }
    const key = event.target.value.toLowerCase();
    const searchResult = Object.values(dataSet.all).filter((user) => {
      const { name } = user;
      return name.toLowerCase().includes(key);
    });

    setCurrentSearchData(searchResult);
  };

  const selectedCount = (
    <SelectedCount>
      ({Object.keys(dataSet.selected).length} / {data.length})
    </SelectedCount>
  );

  const title = (
    <div>
      <TitleWrapper>
        {state.segments ? optionLabel[state.segments] : "All"} Users
        {selectedCount}
      </TitleWrapper>

      <SubTitleWrapper>
        <SearchWrapper>
          <Input onChange={onSearch} placeholder="Search" />
        </SearchWrapper>
        <SelectAllWrapper>
          <span>Select All</span> <Checkbox checked={isAllSelected} onChange={onSelectAll} />
        </SelectAllWrapper>
      </SubTitleWrapper>
    </div>
  );
  const dataSource = currentSearchData || data;

  const renderContacts = () => {
    if (loading) return <Skeleton active />;
    if (data.length === 0) return "No Data Found";

    const pageOffset = (currentPage - 1) * 20;
    const renderedData = dataSource.slice(pageOffset, pageOffset + 20);
    return renderedData.map((user) => (
      <UserItem onCheck={onCheckUser} isChecked={dataSet.selected[user.id]} key={user.id} user={user} />
    ));
  };

  return (
    <UserListWrapper>
      <Card title={title}>
        <CardContentWrapper ref={topList}>
          <div>{renderContacts()}</div>
        </CardContentWrapper>
        <div>
          {!loading && (
            <Pagination
              hideOnSinglePage
              onChange={onChangePage}
              size="small"
              showSizeChanger={false}
              total={dataSource.length}
              pageSize={20}
              current={currentPage}
            />
          )}
        </div>
      </Card>
      {state.formErrors[field.identifier] && !loading && (
        <Typography.Text type="danger" strong>
          This field is required
        </Typography.Text>
      )}
    </UserListWrapper>
  );
};

export default UserBroadcast;
