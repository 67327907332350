import { Entity } from "@thewebops/admin-panel";
import UserBroadcast from "components/UserBroadcast/";
import Message from "components/UserBroadcast/Message";
import "styles/customForm.scss";

export default (authConfig) =>
  new Entity({
    title: "Send Broadcast",
    //subTitle: `Choose specific users or segments`,
    path: "/sendBroadcast",
    actionLabel: "Send",
    logoTitle: "Nutivate",
    action: "create",
    submitButtonText: "Send",
    showInSidebar: true,
    showInCollapsedSideBar: true,
    showAuthFormNotification: false,
    customDataFormClass: "send-broadcast",
    customErrorKeys: ["error"],
    fields: [
      {
        label: "Segments",
        type: "select",
        identifier: "segments",
        options: [
          { value: "all", label: "All" },
          { value: "active", label: "Active Users" },
          { value: "partially_active", label: "Partially Active" },
          { value: "not_active", label: "Not Active Users" },
        ],
        defaultValue: "all",
        dependee: true,
      },
      {
        identifier: "segment_users",
        label: "Users",
        required: true,
        formComponent: UserBroadcast,
      },

      {
        identifier: "sent_message",
        label: "Message",
        required: true,
        formComponent: Message,
      },
    ],
    requestConfig: {
      url: `/${authConfig.serverPluralName}/chat_engine/broadcasts`,
      method: "post",
      saveToLocalStorage: false,
      successMessage: "Message has been broadcasted",
      errorMessage: "Couldn't send message",
      payloadWrapper: {
        name: "broadcast",
        fields: ["targeted_participants", "message"],
      },
      authenticated: true,
      updateAuthAfterSuccess: false,
      persistAuthAfterSuccess: true,
    },
  });
