import React from "react";
import { rtlLanguages, resources } from "translation/i18n";
import logo from "assets/logo.svg";
import landing from "assets/landing.jpg";
import { API_BASE_URL, CABLE_BASE_URL } from "utils/constants";
import { groups, userDefinedEntities } from "./entities";
import { authConfig, authUserDefinedEntities } from "authEntities";
import { customTheme } from "components/Theme";
import { store } from "./index";
import rootReducer from "./reducers";
import { history } from "configureStore";
import playNotificationSound from "utils/playNotificationSound";
import ConversationHeader from "./components/ConversationHeader";
import UserInfo from "./components/UserInfo";

const storeDispatcher = (type) => {
  store.dispatch({ type });
};

const replaceReducer = (adminPanelReducers) => {
  const nextReducer = rootReducer(history, adminPanelReducers);
  store.replaceReducer(nextReducer);
};

const chatCustomConfigs = {
  enabled: true,
  chatOpsWeb: {
    conversationNameInChat: (conversation) => <ConversationHeader conversation={conversation} />,
    UserInfoModalContent: UserInfo,
    getIdFromConversation: (conversation) => conversation?.target_participation?.participant?.id,
    isBroadcastEnabled: false,
    broadcastUserList: [{ label: { en: "Users", ar: "المستخدمين" }, value: "users" }],
    theme: {
      primaryColor: "rgb(0, 46, 58)",
      background: "https://res.cloudinary.com/thewebops/image/upload/v1600629686/background_jvg2z3.jpg",
      smallEmoji: true,
    },
  },
  chatopsActionCable: {
    apiEndpoints: {
      sendMessage: {
        endpoint: (id) => `/conversations/${id}`,
        method: "PUT",
      },
      fetchMessages: {
        endpoint: (id) => `/conversations/${id}`,
        method: "GET",
      },
      sendTyping: {
        endpoint: (id) => `chat_engine/conversations/${id}/typing`,
        method: "post",
      },
      updateLastAppearance: {
        endpoint: (id) => `chat_engine/conversations/${id}/last_appearance`,
        method: "put",
      },
      fetchContacts: {
        endpoint: () => "users",
        method: "get",
      },
      fetchContact: {
        endpoint: (id) => `users/${id}/info`,
        method: "get",
      },
      broadcast: {
        endpoint: () => "chat_engine/broadcasts",
        method: "post",
      },
    },
    conversationSearchParamName: "username",
  },
  senderType: "Coach",
  nameSpace: "coaches",
  cableURL: CABLE_BASE_URL,
};
const {
  REACT_APP_FIREBASE_KEY = "AIzaSyCvDlRuFnZ9ocrFNIp9RLwrlUjWiOvdROU",
  REACT_APP_AUTH_DOMAIN = "guc-pocket.firebaseapp.com",
  REACT_APP_PROJECT_ID = "guc-pocket",
  REACT_APP_STORAGE_BUCKET = "guc-pocket.appspot.com",
  REACT_APP_SENDER_ID = 761405181981,
  REACT_APP_DATABASE = "https://lirten-hub.firebaseio.com",
  REACT_APP_FIREBASE_APP_ID = "1:761405181981:web:0e41d93634469ba046f6f4",
  REACT_APP_FIREBASE_VAPID_KEY = "BO2ZmHQfmq_lvmt0kO8TI-HKLkHEt_MID6e0GHXlNwkF6NaKh1o4B6H2r7d-5F601me0DIJpHp9KSFZzCaQW2p8",
} = process.env;

const notificationConfigs = {
  enabled: true,
  baseURL: `${API_BASE_URL}/coaches`,
  authHeaders: "authHeaders",
  defaultImageUrl: "https://res.cloudinary.com/webopsv2/image/upload/v1636280162/images_raevqs.png",
  onReceiveNotification: playNotificationSound,
  displayAsLink: () => "/app/chat",
  firebaseConfig: {
    apiKey: REACT_APP_FIREBASE_KEY,
    authDomain: REACT_APP_AUTH_DOMAIN,
    databaseURL: REACT_APP_DATABASE,
    projectId: REACT_APP_PROJECT_ID,
    storageBucket: REACT_APP_STORAGE_BUCKET,
    messagingSenderId: REACT_APP_SENDER_ID,
    appId: REACT_APP_FIREBASE_APP_ID,
    vapidKey: REACT_APP_FIREBASE_VAPID_KEY,
  },
  theme: {
    notificationLayout: {
      dropDownAnimation: "opacity",
      displayImages: false,
      iconSize: 1.8,
      itemTheme: "hamza",
      headerHeight: 62,
      top: 140,
      alignment: -57,
      showEmptyIcon: true,
      dropDownHeight: 64.4,
      showArrow: true,
    },
    notificationColors: {
      date: "#505050",
      icon: "#fff",
      iconHover: "#808080",
      scrollBarThumb: "#505050",
      scrollBarTrack: "#f5f5f5",
      unreadNotification: "#40a9ff",
      background: "#fff",
      markAsRead: "#000",
      markAsReadHover: "#000",
    },
  },
};

export const adminConfigs = {
  languageResources: resources,
  disableLocalization: true,
  rtlLanguages,
  logo,
  landingImage: landing,
  fullLogo: logo,
  url: API_BASE_URL,
  groups,
  userDefinedEntities,
  authUserDefinedEntities,
  authConfig,
  theme: customTheme,
  privateRouteURL: "/app",
  storeDispatcher,
  replaceReducer,
  chatConfigs: chatCustomConfigs,
  notificationConfigs,
  //customRoutes,
};
