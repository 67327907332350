import { Entity } from "@thewebops/admin-panel";

export default new Entity({
  name: "savedReplyCategory",
  pluralName: "savedReplyCategories",
  pathName: "saved_reply_categories",
  endpoint: "/coaches/saved_reply_categories",
  serverName: "saved_reply_category",
  serverPluralName: "saved_reply_categories",
  displayName: "Saved Reply Categories",
  allowCreate: true,
  allowEdit: true,
  allowDelete: true,
  showRowActions: true,
  showSearch: false,
  titleField: "name",
  fields: [
    {
      identifier: "id",
      label: "ID",
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "name",
      label: "Name",
      accessor: "name",
      inList: true,
      type: "string",
      required: true,
    },
  ],
});
