import React, { memo } from "react";
import { UserImage, UserItemWrapper } from "./styled";
import { Checkbox } from "antd";

import { adminConfigs } from "@thewebops/admin-panel";

const avatarPlaceHolder = "https://res.cloudinary.com/webopsv2/image/upload/v1636280162/images_raevqs.png";

const UserItem = ({ user, isChecked, onCheck }) => {
  const {
    chatConfigs: { chatOpsWeb },
  } = adminConfigs.getConfigs();

  const { resolveUserImage } = chatOpsWeb;
  const userImage = resolveUserImage(user) || avatarPlaceHolder;

  const onChange = (event) => {
    const { checked } = event.target;
    onCheck(checked, user.id);
  };

  return (
    <UserItemWrapper>
      <div>
        <UserImage src={userImage} alt={"profile"} />
        {user.name}
      </div>
      <Checkbox onChange={onChange} checked={isChecked} />
    </UserItemWrapper>
  );
};

export default memo(UserItem);
