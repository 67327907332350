import { Entity } from "@thewebops/admin-panel";
import UserTraffic from "components/UserTraffic/";

export default new Entity({
  name: "user",
  pluralName: "users",
  pathName: "users",
  endpoint: "/coaches/users",
  serverName: "user",
  serverPluralName: "users",
  displayName: "Users",
  allowCreate: false,
  allowEdit: false,
  allowDelete: false,
  showRowActions: false,
  showSearch: true,
  titleField: "name",
  fields: [
    {
      identifier: "id",
      label: "ID",
      accessor: "id",
      inList: false,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "email",
      label: "Email",
      accessor: "email",
      inList: true,
      type: "email",
      required: true,
      usedToShow: true,
    },
    {
      identifier: "name",
      hideInCreate: true,
      label: "Name",
      accessor: "name",
      inList: true,
      type: "string",
      required: true,
    },
    {
      identifier: "last_active_at",
      hideInCreate: true,
      hideInEdit: true,
      label: "Activity",
      accessor: "last_active_at",
      inList: true,
      type: "string",
      component: UserTraffic,
    },
    {
      identifier: "age",
      hideInCreate: true,
      label: "Age",
      accessor: "age",
      inList: false,
      type: "string",
      required: true,
    },
    {
      identifier: "phone_number",
      hideInCreate: true,
      label: "Phone number",
      accessor: "phone_number",
      inList: false,
      type: "string",
      required: true,
    },

    {
      identifier: "initial_assesment_completed",
      label: "Initial Assessment",
      accessor: (dataObject) => (dataObject.initial_assesment_completed ? "True" : "False"),
      hideInCreate: true,
      inList: true,
      type: "string",
      required: true,
    },
    {
      identifier: "source_program_id",
      label: "Program",
      formAccessor: (dataObject) => (dataObject.program ? dataObject.program.id : null),
      accessor: (dataObject) => (dataObject.program ? dataObject.program.name : null),
      type: "reference",
      inList: true,
      required: true,
      optionsEntity: {
        name: "program",
        labelField: "name",
        valueField: "id",
      },
    },
    {
      identifier: "payment_type",
      label: "Payment Type",
      accessor: "payment_type",
      inList: true,
      type: "select",
      required: true,
      options: [
        { value: "pre_paid", label: "Prepaid" },
        { value: "regular", label: "Regular" },
      ],
    },
  ],
  filters: [
    {
      label: "Program",
      name: "program_id",
      type: "select",
      // selectConfig is required if [select] type is used otherwise it's ommitted
      selectConfig: {
        defaultValue: "first",
        optionsType: "dynamic",

        // optionsEntity is required if optionsType is dynamic
        optionsEntity: {
          name: "program",
          labelField: "name",
          valueField: "id",
        },
      },
    },
    {
      label: "Activity Status",
      name: "activity_status",
      type: "select",
      // selectConfig is required if [select] type is used otherwise it's ommitted
      selectConfig: {
        optionsType: "static",
        optionsArray: [
          { label: "Active", value: "active" },
          { label: "Partially Active", value: "partially_active" },
          { label: "Not Active", value: "not_active" },
        ],
      },
    },
  ],
});
