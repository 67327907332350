import React from "react";
import UserTraffic from "../UserTraffic";
import { ConversationHeaderWrapper, UserTrafficWrapper } from "./styled";

const ConversationHeader = ({ conversation }) => {
  return (
    <ConversationHeaderWrapper>
      <span>{conversation.target_participation.participant.name}</span>
      <UserTrafficWrapper>
        <UserTraffic
          width="10px"
          height="10px"
          value={conversation?.target_participation?.participant?.last_active_at}
        />
      </UserTrafficWrapper>
    </ConversationHeaderWrapper>
  );
};

export default ConversationHeader;
