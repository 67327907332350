import { Entity } from "@thewebops/admin-panel";
import UserTraffic from "components/UserTraffic/";

export default new Entity({
  name: "checkup",
  pluralName: "checkups",
  pathName: "checkups",
  endpoint: "/coaches/checkups",
  serverName: "checkup",
  serverPluralName: "checkups",
  displayName: "checkups",
  allowCreate: false,
  allowEdit: false,
  allowDelete: false,
  showRowActions: false,
  showSearch: true,
  export: {
    enabled: true,
    extension: "xls",
    fileName: "Checkups",
  },
  filters: [
    {
      label: "Program",
      name: "program_id",
      type: "select",
      // selectConfig is required if [select] type is used otherwise it's ommitted
      selectConfig: {
        optionsType: "dynamic",
        defaultValue: "first",

        // optionsEntity is required if optionsType is dynamic
        optionsEntity: {
          name: "program",
          labelField: "name",
          valueField: "id",
        },
      },
    },
    {
      label: "Interactive",
      name: "is_initial",
      type: "select",
      // selectConfig is required if [select] type is used otherwise it's ommitted
      selectConfig: {
        optionsType: "static",

        // optionsEntity is required if optionsType is dynamic
        optionsArray: [
          {
            label: "All",
            value: "",
          },
          {
            label: "Is Initial",
            value: true,
          },
        ],
      },
    },
    {
      label: "Start Date",
      name: "start_date",
      type: "date",
      // selectConfig is required if [select] type is used otherwise it's ommitted
      selectConfig: {
        optionsType: "static",
      },
    },
    {
      label: "End Date",
      name: "end_date",
      type: "date",
      // selectConfig is required if [select] type is used otherwise it's ommitted
      selectConfig: {
        optionsType: "static",
      },
    },
  ],
  fields: [
    {
      identifier: "last_active_at",
      hideInCreate: true,
      hideInEdit: true,
      label: "Activity",
      accessor: (dataObject) => (dataObject.user ? dataObject.user.last_active_at : null),
      inList: true,
      type: "string",
      component: UserTraffic,
    },
    {
      identifier: "due_date",
      label: "Due Date",
      accessor: "due_date",
      inList: true,
      type: "date",
      hideInEdit: true,
      required: true,
    },
    {
      identifier: "user_id",
      label: "Username",
      accessor: (dataObject) => (dataObject.user ? dataObject.user.name : null),
      inList: true,
      usedToShow: true,
      type: "string",
      required: true,
    },
    {
      identifier: "overall_feeling_nutibites_habits",
      label: "Overall feeling about nutibites & habits",
      accessor: "overall_feeling_nutibites_habits",

      type: "string",
      required: true,
    },
    {
      identifier: "share_specific_comment_nutibates_habits",
      label: "Share specific comment about nutibates & habits",
      accessor: "share_specific_comment_nutibates_habits",

      type: "string",
      required: true,
    },
    {
      identifier: "workout_option_choosen",
      label: "Workout option choosen",
      accessor: "workout_option_choosen",

      type: "string",
      required: true,
    },
    {
      identifier: "overall_feeling_coach",
      label: "Overall feeling about coach",
      accessor: "overall_feeling_coach",

      type: "string",
      required: true,
    },
    {
      identifier: "coach_reaction_needs",
      label: "Coach reaction to needs and concerns",
      accessor: "coach_reaction_needs",

      type: "string",
      required: true,
    },
    {
      identifier: "coach_reaction_question",
      label: "Coach responsiveness to your questions and concerns",
      accessor: "coach_reaction_question",

      type: "string",
      required: true,
    },
    {
      identifier: "recommend_program",
      label: "Recommend program to a friend",
      accessor: "recommend_program",

      type: "string",
      required: true,
    },
    {
      identifier: "recommendation",
      label: "What are we doing really well?",
      accessor: "recommendation",

      type: "string",
      required: true,
    },
    {
      identifier: "best_service_given",
      label: "What can we do to make your experience more positive?",
      accessor: "best_service_given",

      type: "string",
      required: true,
    },
    {
      identifier: "weight",
      label: "Weight (Kg)",
      accessor: "weight",

      type: "string",
      required: true,
    },
    {
      identifier: "waist_circumference",
      label: "Waist circumference (cm)",
      accessor: "waist_circumference",

      type: "string",
      required: true,
    },
    {
      identifier: "hip_circumference",
      label: "Hip circumference (cm)",
      accessor: "hip_circumference",

      type: "string",
      required: true,
    },
    {
      identifier: "body_fat_percentage",
      label: "Body fat % (optional)",
      accessor: "body_fat_percentage",

      type: "string",
      required: true,
    },
    {
      identifier: "program_helping_wellness",
      label: "On the scale below, how well is the program helping you move towards your goals?",
      accessor: "program_helping_wellness",

      type: "string",
      required: true,
    },
    {
      identifier: "nutrition_habits_confidence",
      label: "In the last few weeks how confident have you felt about following your new nutrition habits each day?",
      accessor: "nutrition_habits_confidence",

      type: "string",
      required: true,
    },
    {
      identifier: "workouts_confidence",
      label: "In the last few weeks, how confident have you felt about your workouts each day?",
      accessor: "workouts_confidence",

      type: "string",
      required: true,
    },
    {
      identifier: "changing_habits_concerns",
      label:
        "When you joined the program, you had some ideas about how you’d like to change your exercise and eating habits. In the last few weeks, is that happening?",
      accessor: "changing_habits_concerns",

      type: "string",
      required: true,
    },
    {
      identifier: "good_changes",
      label: "What changes do you feel good about over the last few weeks?",
      accessor: "good_changes",

      type: "string",
      required: true,
    },
    {
      identifier: "future_member_hopes",
      label: "Over the next few weeks, what would you like to do better?",
      accessor: "future_member_hopes",

      type: "string",
      required: true,
    },
  ],
});
